<template>
<v-col cols="12" class="init-account bg-brilliant">
 
  <v-row v-if="step == 1">
    <v-col cols="11" sm="12" md="12" lg="12" class="mx-auto  header-section text-center mt-0 mb-5">
                
        <v-img src="../../assets/img/monnaie_pirate_logo.png" class="d-none d-sm-inline-flex" width="230"/>
        <v-img src="../../assets/img/monnaie_pirate_logo.png" class="d-inline-flex d-sm-none" width="150"/>

        <h1 class="font-main pb-1 pt-5 mb-5 pb-5 px-5 yellow--text text--darken-2">
          Bienvenue<br>parmis les pirates !
        </h1><br>

    </v-col>

    <v-col cols="11" sm="10" md="8" lg="7" v-if="step == 1"
          class="mx-auto bg-paper font-papyrus text-left text-paper" 
          style="margin-top:25px;padding:30px;">
          <br>
      <h2 class="font-papyrus">Cher {{ $store.state.auth.user.name }} !</h2><br>
      Ça y est ! Tu as enfin ton propre compte en Monnaie Pirate !<br>
      10.15 PRT ont été créés pour toi, et ont été versés sur ton compte courant.
      <br><br>

      Soit l'équivalent de 1015€ de pouvoir d'achat, que tu peux utiliser dès maintenant de plusieurs façons : <br><br>

      <v-row>
        <v-col cols="12">
            <v-icon class="mb-1 mr-2">mdi-sail-boat</v-icon>
            En jouant à notre jeu de bataille navale, pour nous aider à lever la malédiction des Bermudes.
          
        </v-col>
        <v-col cols="12">
            <v-icon class="mb-1 mr-2">mdi-cart-arrow-right</v-icon>
            En achetant des biens et des services dans la section "Marché Pirate"
          
        </v-col>
        <v-col cols="12">
            <v-icon class="mb-1 mr-2">mdi-map-search</v-icon>
            En passant par un commerçant Pirate, qui accèpte les PRT.
          
        </v-col>
      </v-row>
      <!-- 1 - En jouant à notre jeu de bataille navale, pour nous aider à lever la malédiction des Bermudes.<br>
      2 - En achetant des biens et des services dans la section "Marché Pirate"<br>
      3 - En passant par un commerçant Pirate, qui accèpte les PRT près de chez-toi, et que tu peux localiser depuis la carte principale. -->

      <br>
      <v-divider></v-divider>
      <br>
      A partir d'aujourd'hui, tu bénéficies de notre création monétaire quotidienne.
      Tu recevras donc des PRT tous les jours, en fonction de la quantité de monnaie déjà présente sur ton compte,
      selon le principe suivant : plus ton compte sera vide, plus il se remplira vite. 
      <br><br>
      <v-divider></v-divider>
      <br>
      Notre objectif est de pirater le capitalisme, ne l'oublie jamais !<br><br>
      Ça signifie qu'il faudra de l'audace, du courage, et de la persévérance.<br>
      Les premiers pirates seront les pionniers d'un nouveau monde. Ceux qui ouvriront la voix à tous les autres.
      Mais ça ne sera pas facile ! 
      <br><br>
      Il faudra user de votre ingéniosité pour faire passer le message 
      et démontrer tous les bienfaits de la Monnaie Pirate.<br><br>
      Car plus elle circulera, plus elle prendra de la valeur, et plus elle affaiblira toutes les autres monnaies.<br><br>
      Tout l'enjeu est là.<br>Il nous dépasse tous.<br>
      Et pourant, il est à portée de main...
      <br><br><br>
      
      <v-divider></v-divider>
      <br>
      <div class="d-block text-right">
        <v-btn large color="primary" @click="nextStep()" class="black--text">
          Continuer <v-icon class="ml-1">mdi-arrow-right-circle</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
  <v-row  style="padding-bottom:120px;">

    <v-col cols="11" sm="10" md="8" lg="7"  v-if="step == 2"
          class="mx-auto bg-paper pa-5 font-papyrus text-left text-paper" 
          style="margin-top:25px;">

      <h2 class="font-papyrus text-center">
        <v-img src="../../assets/img/black_fire_crew-600.png" class="d-none d-sm-inline-flex"
               style="border:2px solid #fbc02d;border-radius:2px;margin:10px 0 30px 0;"
               width="50%"/>
        <v-img src="../../assets/img/black_fire_crew-600.png" class="d-inline-flex d-sm-none"
               style="border:2px solid #fbc02d;border-radius:2px;margin:10px 0 30px 0;"
               width="90%"/>
        <br>
        Rejoindre le <i class="d-inline-block primary--text">Black Faya Crew ?</i>
      </h2>
      <br>
      <v-divider></v-divider><br>
      Comme tu pourras le constater, pour l'instant, le nombre de pirates enregistrés n'est pas encore très élevé.
      C'est pourquoi nous avons besoin de renforts pour nous aider faire grossir nos rangs.

      <br><br>
      Le <i class="primary--text">Black Faya Crew</i> est le nom du groupe de volontaires ayant pour mission de
      faire connaître la Monnaie Pirate le plus largement possible, sur internet, et dans la "vraie vie".
      <br><br>
      Les membres de ce groupe recevront régulièrement des instructions par e-mails,
      pour leur indiquer de quelles façons ils peuvent nous aider.
      <br><br>
      Si tu veux en faire parti, coche la case, et on te recontactera très bientôt.
      <br><br>
      <v-divider></v-divider>
      <br>

      <v-checkbox v-model="bfc" class="d-inline" label="Je veux faire parti du Black Faya Crew"></v-checkbox>

      <v-divider></v-divider>
      <br>
      <div class="d-block text-right">
        <v-btn large color="primary" @click="saveInCrew()" class="black--text">
          Continuer <v-icon class="ml-1">mdi-arrow-right-circle</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>

</v-col>
</template>

<style>
.init-account h1.font-main{
  line-height:110px !important;
  margin-top:-80px;
}
.init-account .text-paper{
  font-size:20px;
  padding:40px;
}
/* MD */
@media (max-width: 960px) {
  .init-account h1.font-main{
    line-height:70px !important;
    margin-top:-40px;
  }
}
/* XS */
@media (max-width: 599px) {
  .init-account h1.font-main{
    font-size:60px;
    line-height:50px !important;
    margin-top:-30px;
  }
  .init-account .text-paper{
    font-size:13px !important;
  padding:20px;
  }
}
</style>

<script>

import axios from 'axios'
import router from '../../router/router'

export default {
  name: 'first-step',
  components: {  },
  data: () => ({
    bfc : false,
    step: 1
  }),
  async mounted(){
    console.log("this.$route.params.stepInit", this.$route.params.stepInit)
    if(this.$route.params.stepInit != null) this.step = this.$route.params.stepInit
    this.bfc = this.$store.state.auth.user.inBFC != null ? this.$store.state.auth.user.inBFC : false
  },
  methods: {
    saveInCrew(){
      axios.post('/user/set-in-BFC', { inBFC: this.bfc }).then(async ()=>{
        await this.$store.dispatch('auth/refreshUserData')
        router.push('/dashboard')
      })
    },
    nextStep(){
      this.step = 2
      window.scrollTo(0, 0)
    }

  },
  computed: {
    
    
  }
}
</script>
